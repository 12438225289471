import { useRequest } from "ahooks";
import {
  Flex,
  Spacer,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  CircularProgress,
  Td,
  TableCaption,
  VStack,
  StackDivider,
  Icon,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import axios from "axios";
import {
  SystemConst,
  AreaHash,
  AreaTagetNames,
  FormatDate,
  IHistoryInterface,
  IAnnouncementHistory,
  FormatDateYMDHM,
} from "../SystemConst";
import { ListRefreshButton } from "../partial/ListRefreshButton";
import { MyPagination } from "../partial/MyPagination";
import { useState, useEffect, useRef } from "react";

/**
 *
 * @param props department: (EAST | WEST | CENTER | TOKYO | NAGOYA | WNI)
 * @returns 「お知らせ」のDB一覧（登録日時:Desc）
 *
 */
export const AnnouncemenHistoryList: React.VFC<IHistoryInterface> = (props) => {
  const apiPath = "/api/announcement-history";
  const showListCount: number = 10;
  const isFirstRender = useRef(true);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [totalPageCnt, setTotalPageCnt] = useState(0);
  const handlePaginate = (page: number) => {
    setCurrentPageNum(page); // <<< これでデータを取得に行く
  };

  // アカウントが Admin権限（WNI）かどうか  // Add20230702
  const isAdminDepartment: boolean =
    props.dep === SystemConst.AdminDepartment ? true : false;

  async function historyData2(
    pageNum: number,
  ): Promise<IAnnouncementHistory[]> {
    const dataList = await axios({
      method: "GET",
      url: `${apiPath}/departmentPage/${props.dep}/${pageNum}/${showListCount}`,
    })
      .then((responseList) => {
        return responseList.data;
      })
      .catch((error) => {
        console.log(error.status);
        alert("データの取得に失敗しました");
        return [];
      });

    await axios({
      method: "GET",
      url: `${apiPath}/countAster/${props.dep}`,
    })
      .then((responseCount) => {
        setTotalPageCnt(responseCount.data);
        return;
      })
      .catch((error) => {
        console.log(error.status);
        alert("全体件数の取得に失敗しました");
        return;
      });

    return dataList;
  }

  const { data, run, loading, refresh } = useRequest<
    IAnnouncementHistory[],
    []
  >(() => historyData2(currentPageNum));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum]);

  const InfoShareIcon = (rowData: IAnnouncementHistory) => {
    if (rowData.shareStatus === 0) {
      return <></>;
    }
    return <Icon as={InfoIcon} color="gray.400" mr="2" />;
  };

  if (loading) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }

  return (
    <Box overflow="auto" h="100vh" pb={5}>
      <Flex>
        <Spacer />
        <ListRefreshButton
          func={async () => {
            await refresh();
          }}
        />
      </Flex>
      <Table variant="striped" colorScheme="blackAlpha" size="sm">
        <TableCaption>{SystemConst.AnnouncementTitle}履歴</TableCaption>
        <Thead>
          <Tr>
            <Th w={400}>タイトル / URL</Th>
            {/** Mdo20230702 <Th>エリア</Th> */}
            {isAdminDepartment ? <Th>エリア</Th> : <></>}
            <Th w={170}>掲載期間</Th>
            <Th>操作</Th>
            <Th>所属</Th>
            <Th>担当者</Th>
            <Th>実行日時</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data.map((i: IAnnouncementHistory) => (
              <Tr key={i.id}>
                <Td wordBreak={"break-all"} fontSize="xs">
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    align="stretch"
                  >
                    <Box>
                      {InfoShareIcon(i)}
                      {i.title}
                    </Box>
                    <Box>{i.url}</Box>
                  </VStack>
                </Td>
                {/** Mod20230702*/}
                {isAdminDepartment ? (
                  <Td fontSize="xs">{AreaHash[i.area]}</Td>
                ) : (
                  <></>
                )}
                <Td fontSize="xs">
                  <VStack
                    divider={<StackDivider borderColor="gray.200" />}
                    align="stretch"
                  >
                    <Box>
                      {i.fromDate ? FormatDateYMDHM(i.fromDate) : ""} 〜
                    </Box>
                    <Box>{i.toDate ? FormatDateYMDHM(i.toDate) : ""}</Box>
                  </VStack>
                </Td>
                <Td fontSize="xs">{i.operation}</Td>
                <Td fontSize="xs">{AreaTagetNames[i.department]}</Td>
                <Td fontSize="xs">{i.person}</Td>
                <Td fontSize="xs">{FormatDate(i.createdAt)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <MyPagination
        curr={currentPageNum}
        sum={totalPageCnt}
        per={showListCount}
        onChange={(e) => handlePaginate(e.page)}
      />
    </Box>
  );
};
