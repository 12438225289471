import { useState, useRef, useCallback, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from "@chakra-ui/react";
import { IWinterDrivingTipModalRegForm } from "../SystemConst";
import { MyImageUpload } from "./MyImageUpload";
import { MyDateTimePicker } from "./MyDateTimePicker";
import { MyAlertDialog } from "./MyAlertDialog";

export const WinterDrivingTipModalRegForm: React.FunctionComponent<{
  param: IWinterDrivingTipModalRegForm;
}> = ({ param }) => {
  const headStr: string = param.props.id ? "編集" : "新規作成";
  const buttonLabel: string = param.props.id ? "編集" : "登録";

  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [order, setOrder] = useState(1);
  const [fromDate, setFromDate] = useState<Date>(param.props.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(
    param.props.toDate ? param.props.toDate : undefined,
  );
  const [thumbnailImgs, setThumbnailImgs] = useState<string[]>([]);

  useEffect(() => {
    setId(param.props.id);
    setTitle(param.props.title);
    setUrl(param.props.url);
    setThumbnail(param.props.thumbnail);
    setOrder(param.props.order);
    setThumbnailImgs(param.props.thumbnail ? [param.props.thumbnail] : []);
    setFromDate(param.props.fromDate);
    setToDate(param.props.toDate ? param.props.toDate : undefined);
  }, [param.props]);

  const inputTitleEl = useRef<HTMLInputElement>(null);
  const focusEl = useRef<HTMLButtonElement>(null);

  const handleFuncClose = () => {
    param.funcClose();
  };

  const onDrops = useCallback((imgs: string[]) => {
    setThumbnail(imgs[0]);
  }, []);

  const uploadFiles = async (params: {
    title: string;
    url: string;
    thumbnail: string;
    order: number;
    fromDate: Date;
    toDate?: Date;
  }) => {
    try {
      await param.funcUploadImg(id, params);
      await param.funcClose();
      await param.funcRefresh();
    } catch (error) {
      alert("登録に失敗しました");
    }
  };

  const handleFromDateOnChange = (dt: Date) => {
    setFromDate(dt);
  };
  const handleToDateOnChange = (dt: Date | undefined) => {
    setToDate(dt);
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const [validMessage, setValidMessage] = useState("");
  const showAlertToFocusElememt = (
    msg: string,
    el: string | React.RefObject<HTMLElement> | undefined,
  ) => {
    setValidMessage(msg);
    setIsAlertOpen(true);
    /*
    if (el !== undefined) {
      if (typeof el === "string") {
        document.getElementById(el)?.focus();
      } else {
        if (!el.current) {
          console.info("Element is not assigned:");
          throw Error("Element is not assigned");
        }
        el.current.focus();
      }
    }
    */
    throw Error("throw: Show Alert Dialog");
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        initialFocusRef={focusEl}
        isOpen={param.isOpenFlag}
        onClose={handleFuncClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headStr}</ModalHeader>
          <ModalCloseButton ref={focusEl} />
          <ModalBody pb={{ base: 20, md: 5 }}>
            <FormControl id="title" mb={5} isRequired>
              <FormLabel>タイトル</FormLabel>
              <Input
                type="text"
                ref={inputTitleEl}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="url" mb={5}>
              <FormLabel>URL</FormLabel>
              <Input
                type="text"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </FormControl>
            <FormControl id="thum" mb={5} isRequired>
              <FormLabel>ファイル</FormLabel>
              <MyImageUpload
                images={thumbnailImgs}
                isMultipleUpload={false}
                buttonText={"画像を選択"}
                maxFileSize={10485760} // 10MB
                maxImagesUpload={1} // 画像を最大 N枚 まで選択・アップロード
                onChange={onDrops}
              />
            </FormControl>
            <FormControl id="ord" mb={5} isRequired>
              <FormLabel>優先度</FormLabel>
              <NumberInput
                value={order}
                clampValueOnBlur={false}
                onChange={(_, valueAsNumber) => {
                  if (!isNaN(valueAsNumber)) {
                    setOrder(valueAsNumber);
                  }
                }}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl id="validPeriod" mb={5} isRequired>
              <FormLabel>掲載期間</FormLabel>
              <Box display={{ md: "flex" }} alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box display={{ md: "none" }} mr="3">
                    From：
                  </Box>
                  <MyDateTimePicker
                    id="inputFromDate"
                    placeholder="開始日（必須）"
                    initialvalue={param.props.fromDate}
                    isClear={false}
                    onChange={handleFromDateOnChange}
                  />
                </Box>
                <Box ml={3} mr={3}>
                  〜
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display={{ md: "none" }} mr="3">
                    To ：
                  </Box>
                  <MyDateTimePicker
                    id="inputToDate"
                    placeholder="終了日（任意）"
                    initialvalue={
                      param.props.toDate ? param.props.toDate : undefined
                    }
                    isClear={true}
                    onChange={handleToDateOnChange}
                  />
                </Box>
              </Box>
            </FormControl>
            <Button
              colorScheme="teal"
              size="md"
              onClick={async () => {
                try {
                  if (!title.trim()) {
                    showAlertToFocusElememt(
                      "[タイトル]を入力してください",
                      inputTitleEl,
                    );
                  }
                  if (!thumbnail) {
                    showAlertToFocusElememt(
                      "[画像]を選択してください",
                      undefined,
                    );
                  }
                  if (isNaN(order)) {
                    // 念のためね
                    alert("優先度を入力してください");
                    throw Error("Order not Selected");
                  }
                  if (!fromDate) {
                    showAlertToFocusElememt(
                      "[掲載期間（開始日）]を指定してください",
                      "inputFromDate",
                    );
                  }
                  if (toDate && fromDate >= toDate) {
                    showAlertToFocusElememt(
                      "[掲載期間]は（From < To）を指定してください",
                      "inputToDate",
                    );
                  }
                } catch (error) {
                  return;
                }

                uploadFiles({
                  title: title,
                  url: url,
                  thumbnail: thumbnail,
                  order: order,
                  fromDate: fromDate,
                  toDate: toDate ? toDate : undefined,
                });
              }}
            >
              {buttonLabel}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <MyAlertDialog
        isOpen={isAlertOpen}
        handleOnClose={onAlertClose}
        validMessage={validMessage}
      />
    </>
  );
};
