import { useState, useCallback, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  Radio,
  RadioGroup,
  Box,
} from "@chakra-ui/react";
import { ISnowPictureModalRegForm } from "../SystemConst";
import { MyImageUpload } from "./MyImageUpload";
import { MyDateTimePicker } from "./MyDateTimePicker";
import { MyAlertDialog } from "./MyAlertDialog";

export const SnowPictureModalRegForm: React.FunctionComponent<{
  param: ISnowPictureModalRegForm;
}> = ({ param }) => {
  const headStr: string = param.isMulti ? "画像の作成（更新）" : "編集";
  const btnStr: string = param.isMulti ? "作成" : "編集";
  const isSingleImg: boolean = param.isMulti ? false : true;
  const imgButtonLabel: string =
    "画像を選択" + (param.isMulti ? "（複数可）" : "");

  const [id, setId] = useState(0);
  const [upTime, setUpTime] = useState("T12");
  const [imageList, setImageList] = useState(new Array<string>());
  const [editType, setEditType] = useState("add");
  const [fromDate, setFromDate] = useState<Date>(param.props.fromDate);
  const [toDate, setToDate] = useState<Date | undefined>(
    param.props.toDate ? param.props.toDate : undefined,
  );
  const [thumbnailImgs, setThumbnailImgs] = useState<string[]>([]);

  useEffect(() => {
    setId(param.props.id);
    setUpTime(param.props.time);
    setImageList(param.props.content ? [param.props.content] : []);
    setThumbnailImgs(param.props.content ? [param.props.content] : []);
    setEditType(param.isMulti ? "cur" : "add");
    setFromDate(param.props.fromDate);
    setToDate(param.props.toDate ? param.props.toDate : undefined);
  }, [param]);
  const focusEl = useRef<HTMLButtonElement>(null);

  const handleFuncClose = () => {
    param.funcClose();
  };

  const onDrops = useCallback((imgs: string[]) => {
    setImageList(imgs);
  }, []);

  const uploadFiles = async (params: {
    images: string[];
    time: string;
    edittype: string;
    fromDate: Date;
    toDate?: Date;
  }) => {
    try {
      await param.funcUploadImg(id, params);
      await param.funcClose();
      await param.funcRefresh();
    } catch (error) {
      alert("登録に失敗しました");
    }
  };
  const handleChange = (value: string) => {
    setEditType(value);
  };
  const editTypeRadio = () => {
    return param.isMulti ? (
      <FormControl id="upaction" isRequired mt={2} mb={3}>
        <FormLabel>更新タイプ（入替 / 追加）</FormLabel>
        <RadioGroup onChange={handleChange} value={editType}>
          <Box display={{ md: "flex" }}>
            <Box mr="5" mb="2">
              <Radio value="cur">当該時間</Radio>
            </Box>
            <Box mr="5" mb="2">
              <Radio value="all">全ての時間</Radio>
            </Box>
            <Box mb="2">
              <Radio value="add">当該時間に追加</Radio>
            </Box>
          </Box>
        </RadioGroup>
      </FormControl>
    ) : (
      <></>
    );
  };

  const handleFromDateOnChange = (dt: Date) => {
    setFromDate(dt);
  };
  const handleToDateOnChange = (dt: Date | undefined) => {
    setToDate(dt);
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const onAlertClose = () => setIsAlertOpen(false);
  const [validMessage, setValidMessage] = useState("");
  const showAlertToFocusElememt = (
    msg: string,
    el: string | React.RefObject<HTMLElement> | undefined,
  ) => {
    setValidMessage(msg);
    setIsAlertOpen(true);
    throw Error("throw: Show Alert Dialog");
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        scrollBehavior="inside"
        initialFocusRef={focusEl}
        isOpen={param.isOpenFlag}
        onClose={handleFuncClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{headStr}</ModalHeader>
          <ModalCloseButton ref={focusEl} />
          <ModalBody pb={{ base: 20, md: 5 }}>
            <FormControl id="files" mb={5} isRequired>
              <FormLabel>ファイル</FormLabel>
              <MyImageUpload
                images={thumbnailImgs}
                isMultipleUpload={!isSingleImg}
                buttonText={imgButtonLabel}
                maxFileSize={10485760} // 10MB
                maxImagesUpload={5} // 作成時の画像を最大 N枚 まで選択・アップロード（編集は１）
                onChange={onDrops}
              />
            </FormControl>
            <FormControl id="uptime" mb={5} isRequired>
              <FormLabel>予測時間</FormLabel>
              <Select
                value={upTime}
                onChange={(e) => {
                  setUpTime(e.target.value);
                }}
              >
                <option value="T12">12時間</option>
                <option value="T24">24時間</option>
                <option value="T72">72時間</option>
              </Select>
            </FormControl>
            {editTypeRadio()}
            <FormControl id="validPeriod" mb={5} isRequired>
              <FormLabel>掲載期間</FormLabel>
              <Box display={{ md: "flex" }} alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box display={{ md: "none" }} mr="3">
                    From：
                  </Box>
                  <MyDateTimePicker
                    id="inputFromDate"
                    placeholder="開始日（必須）"
                    initialvalue={param.props.fromDate}
                    isClear={false}
                    onChange={handleFromDateOnChange}
                  />
                </Box>
                <Box ml={3} mr={3}>
                  〜
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display={{ md: "none" }} mr="3">
                    To ：
                  </Box>
                  <MyDateTimePicker
                    id="inputToDate"
                    placeholder="終了日（任意）"
                    initialvalue={
                      param.props.toDate ? param.props.toDate : undefined
                    }
                    isClear={true}
                    onChange={handleToDateOnChange}
                  />
                </Box>
              </Box>
            </FormControl>
            <Button
              colorScheme="teal"
              size="md"
              onClick={async () => {
                try {
                  if (imageList.length < 1) {
                    showAlertToFocusElememt(
                      "[画像]を選択してください",
                      undefined,
                    );
                  }
                  if (!fromDate) {
                    showAlertToFocusElememt(
                      "[掲載期間（開始日）]を指定してください",
                      "inputFromDate",
                    );
                  }
                  if (toDate && fromDate >= toDate) {
                    showAlertToFocusElememt(
                      "[掲載期間]は（From < To）を指定してください",
                      "inputToDate",
                    );
                  }
                } catch (error) {
                  return;
                }

                uploadFiles({
                  images: imageList,
                  time: upTime,
                  edittype: editType,
                  fromDate: fromDate,
                  toDate: toDate ? toDate : undefined,
                });
              }}
            >
              {btnStr}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <MyAlertDialog
        isOpen={isAlertOpen}
        handleOnClose={onAlertClose}
        validMessage={validMessage}
      />
    </>
  );
};
